import React, { useState, useMemo, useEffect } from 'react';
import { Dialog, DialogContent, IconButton, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ReactPlayer from 'react-player/youtube';

const YouTubeDialog = ({ open, onClose, videoId }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const closeButtonStyles = useMemo(() => ({
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Slightly transparent background
    color: 'white', // White close button
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)', // Hover effect
    },
  }), []);

  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  // Reset state when dialog is closed
  useEffect(() => {
    if (!open) {
      setIsPlaying(false); // Stop playing when closed
    }
  }, [open]);

  // Handle thumbnail click to start playing the video
  const handleThumbnailClick = () => {
    setIsPlaying(true); // Start playing the video
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        sx: {
          bgcolor: 'transparent', // Transparent dialog background
          boxShadow: 'none',
        },
      }}
      BackdropProps={{
        style: { backgroundColor: 'transparent' }, // No dark background
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: '800px',
          bgcolor: 'transparent', // Transparent background
          p: 0,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={closeButtonStyles}
        >
          <CloseIcon />
        </IconButton>

        {!isPlaying ? (
          // Show thumbnail initially
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleThumbnailClick}
          >
            <Box
              component="img"
              src={thumbnailUrl}
              alt="YouTube Thumbnail"
              sx={{
                width: '100%',
                borderRadius: '8px',
              }}
            />
          </Box>
        ) : (
          // Show video player once thumbnail is clicked
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${videoId}`}
            controls={true}
            playing={true} // Start playing the video immediately
            width="100%"
            height="100%"
            style={{ borderRadius: '8px', overflow: 'hidden' }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default YouTubeDialog;
